export default {
  white: "#FFFFFF",
  black: "#000000",
  lightGrey: "#eeeeee",
  mediumGrey: "#919191",
  grey: "#BBBBBB",
  darkGrey: "#707070",
  blue: "#4382FF",
  darkBlue: "#4F6A9F",
  success: "#28a745",
  error: "#ff0033",
};
