import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import React, { useContext } from "react";
import PropTypes, { object } from "prop-types";
import L from "leaflet";
import AppContext from "../lib/context";

const parkingMarker = (props) => {
  const { position, children, popup, showPopup, selected, onView } = props;
  const { state, setState } = useContext(AppContext);
  let iconpath = require("../assets/Pin_Blue.png");
  if (popup) iconpath = require("../assets/Logo_Gray.png");
  if (selected) iconpath = require("../assets/Logo_Blue.png");
  const icon = new L.Icon({
    iconUrl: iconpath,
    iconSize: !popup ? new L.Point(64, 64) : new L.Point(40, 40),
    popupAnchor: [0, -25],
  });
  const initMarker = (ref) => {
    if (ref) {
      ref.leafletElement.getPopup().options.autoClose = false;
      ref.leafletElement.getPopup().options.closeOnClick = false;
      ref.leafletElement.getPopup().options.width = "400px";
      ref.leafletElement.getPopup().options.autoPan = false;
      if ((showPopup && popup) || selected) ref.leafletElement.openPopup();
      else ref.leafletElement.closePopup();
      if (state.viewSidebar && !selected) {
        ref.leafletElement.closePopup();
      }
    }
  };
  const clickMarker = (event) => {
    // event.target.openPopup();
    onView();
  };
  return (
    <Marker
      position={position}
      ref={initMarker}
      icon={icon}
      onClick={clickMarker}
    >
      {React.Children.map(
        children,
        (c, i) => (
          <Popup key={`popup ${i + c}`}>
            <button
              type="button"
              style={{
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              onClick={c.props.onView}
            >
              {c}
            </button>
          </Popup>
        ),
        this
      )}
    </Marker>
  );
};
const parkingMap = (props) => {
  const { center, zoom, children, attribution, onChange, style } = props;
  return (
    <Map
      center={center}
      zoom={zoom}
      onMoveend={(e) => {
        onChange({ zoom: e.target._zoom });
      }}
      style={style}
    >
      <TileLayer
        attribution={
          attribution
            ? "&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
            : ""
        }
        attributionControl={attribution}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </Map>
  );
};
parkingMap.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  zoom: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  attribution: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.instanceOf(object),
};
parkingMap.defaultProps = {
  attribution: true,
  onChange: () => {},
  style: {},
};
parkingMarker.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired,
  popup: PropTypes.bool,
  showPopup: PropTypes.bool,
  selected: PropTypes.bool,
  onView: PropTypes.func,
};
parkingMarker.defaultProps = {
  popup: false,
  showPopup: false,
  selected: false,
  onView: () => {},
};
export default { parkingMap, parkingMarker };
