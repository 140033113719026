import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Button,
  TouchableOpacity,
  ScrollView,
  Animated,
  Easing,
} from "react-native";
import PropTypes from "prop-types";
import Text from "./text";
import brand from "../../assets/styles";
import AppContext from "../../lib/context";

const styles = StyleSheet.create({
  alertContainer: {
    position: "absolute",
    zIndex: 30,
    justifyContent: "center",
    alignItems: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  alertBox: {
    backgroundColor: "black",
    padding: 15,
    height: "100%",
    width: "100%",
    flex: 1,
    maxHeight: 400,
    borderRadius: 5,
    marginTop: 40,
  },
  error: { backgroundColor: brand.error },
  success: { backgroundColor: brand.success },
  info: {},
  alertText: {
    color: brand.white,
    fontSize: 30,
    marginVertical: 10,
  },
  alertButton: {
    borderWidth: 2,
    borderColor: brand.white,
    padding: 5,
  },
  alertButtonText: {
    fontSize: 30,
    color: brand.white,
    fontWeight: "bold",
    textAlign: "center",
  },
});
const Alert = ({ alert, close }) => {
  if (!alert.visible) return null;
  const translateTop = new Animated.Value(-500);
  useEffect(() => {
    Animated.timing(translateTop, {
      toValue: -40,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.elastic(),
    }).start();
  }, []);
  const onClose = () => {
    Animated.timing(translateTop, {
      toValue: -500,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.elastic(),
    }).start(close);
  };
  return (
    <Animated.View
      style={[
        styles.alertContainer,
        { transform: [{ translateY: translateTop }] },
      ]}
    >
      <ScrollView
        contentContainerStyle={{
          justifyContent: "center",
          alignItems: "center",
        }}
        style={[
          {
            flex: 1,
          },
          styles.alertBox,
          styles[alert.type],
        ]}
      >
        <Text style={[styles.alertText, styles[alert.type]]}>
          {alert.message}
        </Text>
        <TouchableOpacity
          style={[styles.alertButton, styles[alert.type]]}
          onPress={onClose}
        >
          <Text style={styles.alertButtonText}>Close</Text>
        </TouchableOpacity>
      </ScrollView>
    </Animated.View>
  );
};

Alert.propTypes = {
  alert: PropTypes.exact({
    message: PropTypes.string,
    type: PropTypes.string,
    visible: PropTypes.bool,
  }),
};
Alert.defaultProps = {
  alert: {
    message: "",
    type: "info",
    visible: false,
  },
};

export default Alert;
