import React from "react";
import {
  View,
  Image,
  StyleSheet,
  Dimensions,
  TextInput,
  TouchableOpacity,
  Animated,
  Easing,
} from "react-native";
import ProprtyShape from "../prop-types/property";
import brand from "../assets/styles";
import Button from "./ui/button";
import Text from "./ui/text";
import { prettyPrintCents } from "../lib/costhandler";
import { formatDate, formatTime } from "../lib/timehandler";
import AppContext from "../lib/context";

const { height, width } = Dimensions.get("window");

const styles = StyleSheet.create({
  paymentContainer: {
    position: "absolute",
    zIndex: 15,
    right: "auto",
    height: height - 200,
    width: width - "10%",
    maxWidth: 1400,
    maxHeight: 795,
    backgroundColor: brand.white,
    borderWidth: 1,
    borderColor: brand.grey,
    flexDirection: "column",
    alignSelf: "center",
  },
  innerContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  innerContainerMobile: {
    top: -70,
  },
  header: {
    height: 70,
    backgroundColor: brand.darkBlue,
    flexDirection: "row",
    alignItems: "center",
  },
  headerMobile: {
    backgroundColor: "transparent",
    zIndex: 1,
  },
  logo: {
    width: 55,
    height: 55,
    marginHorizontal: 10,
  },
  name: {
    color: brand.white,
    paddingVertical: 5,
    marginBottom: 5,
    fontSize: 30,
    flex: 0.99,
  },
  logoMobile: {
    display: "none",
  },
  nameMobile: {
    display: "none",
  },
  rating: {
    flex: 0.1,
  },
  address: {
    textDecorationLine: "underline",
    fontSize: 16,
    fontWeight: "normal",
    width: 300,
    flex: 0.2,
    textAlign: "center",
    justifyContent: "center",
    color: brand.mediumGrey,
    marginBottom: 10,
  },
  image: {
    width: 280,
    height: 170,
    borderColor: brand.grey,
    borderWidth: 1.5,
  },
  spotInfo: {
    flexDirection: "row",
    borderColor: "transparent",
    borderWidth: 1,
    paddingBottom: 5,
    borderBottomColor: brand.darkGrey,
    marginBottom: 5,
  },
  boxesFlex: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "91.5%",
  },
  feature: {
    paddingLeft: 10,
    display: "flex",
    margin: 2,
    flexBasis: "40%",
    flex: 1,
  },
  bookingLabel: {
    fontSize: 20,
    color: brand.darkGrey,
    textAlign: "center",
  },
  priceLabel: {
    fontSize: 26,
    color: brand.darkGrey,
    textAlign: "center",
  },
  icon: {
    width: 47,
    height: 47,
    marginHorizontal: 5,
  },
  propertyColumn: {
    marginVertical: 20,
    width: 440,
    alignItems: "center",
    borderColor: "transparent",
    borderRightColor: brand.darkGrey,
    borderWidth: 2,
  },
  paymentColumn: {
    marginVertical: 20,
    width: "60%",
    alignItems: "center",
  },
  box: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    margin: 5,
    borderColor: brand.darkGrey,
    borderWidth: 1,
    minWidth: "48%",
  },
  doubleBox: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    margin: 5,
    borderColor: brand.darkGrey,
    borderWidth: 1,
    minWidth: "90%",
  },
  boxLabel: {
    alignItems: "center",
    color: brand.grey,
    fontSize: 18,
  },
  featureLabel: {
    position: "relative",
    top: -17,
    color: brand.mediumGrey,
  },
  paymentLabel: {
    alignItems: "center",
    color: brand.darkGrey,
    marginBottom: 10,
    fontSize: 16,
  },
  textInput: {
    padding: 0,
    height: 20,
    fontSize: 20,
    color: brand.darkGrey,
    width: 100,
  },
  hr: {
    borderColor: "transparent",
    borderWidth: 2,
    marginBottom: 10,
    borderBottomColor: brand.darkGrey,
    minWidth: "90%",
  },
  bookingHr: {
    borderColor: "transparent",
    borderWidth: 2,
    marginBottom: 10,
    borderBottomColor: brand.darkGrey,
    width: "100%",
  },
  title: {
    position: "relative",
    top: -14,
    fontSize: 26,
    marginTop: 5,
    marginBottom: 5,
    fontWeight: "normal",
  },
  xButton: {
    height: 64,
    top: 10,
    right: -10,
  },
  buttonText: {
    fontSize: 26,
    marginLeft: 10,
    flex: 1.5,
    color: brand.white,
    alignSelf: "center",
  },
  grey: {
    backgroundColor: brand.grey,
    width: 310,
    height: 80,
    flexDirection: "row",
  },
  titleMain: {
    fontSize: 28,
    color: brand.darkGrey,
    paddingVertical: 5,
    marginBottom: 5,
  },
  paypal: {
    width: 180,
    height: 50,
    alignSelf: "center",
    marginRight: 5,
  },
  paymentContainerMobile: {
    width: "100%",
    height: height - 80,
    flexDirection: "column",
  },
  propertyColumnMobile: {
    width: "100%",
    borderRightColor: "transparent",
  },
  paymentColumnMobile: {
    width: "100%",
  },
  boxMobile: {
    width: "100%",
    marginHorizontal: 0,
  },
  xButtonMobile: {
    alignSelf: "flex-end",
    position: "absolute",
    top: 0,
  },
  boxFlexMobile: {
    width: "90%",
  },
  paddingLower: {
    marginBottom: 40,
  },
  iconMobile: {
    height: 57,
    width: 57,
  },
});
class PaymentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { top: new Animated.Value(-500), mobile: false };
  }

  componentDidMount() {
    if (Dimensions.get("window").width < 500) this.setState({ mobile: true });
    else this.setState({ mobile: false });
  }

  show() {
    const { top, mobile } = this.state;
    if (mobile) {
      Animated.timing(top, {
        toValue: 80,
        easing: Easing.elastic(),
        duration: 300,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(top, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  }

  closeHide(state, setState) {
    const { top } = this.state;
    Animated.timing(top, {
      toValue: -1000,
      easing: Easing.elastic(),
      duration: 300,
      useNativeDriver: false,
    }).start(() => setState({ ...state, payment: false, property: null }));
  }

  render() {
    const { property } = this.props;
    const { top, mobile } = this.state;
    if (!property) return null;
    this.show();
    return (
      <AppContext.Consumer>
        {({ state, setState }) => (
          <Animated.ScrollView
            style={[
              styles.paymentContainer,
              mobile ? styles.paymentContainerMobile : null,
              { top },
            ]}
          >
            <View style={[styles.header, mobile ? styles.headerMobile : null]}>
              <Image
                style={[styles.logo, mobile ? styles.logoMobile : null]}
                source={require("../assets/GO.png")}
              />
              <Text
                white
                style={[styles.name, mobile ? styles.nameMobile : null]}
              >
                Reserve Property
              </Text>
              <TouchableOpacity
                style={[styles.xButton, mobile ? styles.xButtonMobile : null]}
                onPress={() => this.closeHide(state, setState)}
              >
                <Image
                  source={require("../assets/X.png")}
                  style={[styles.icon, mobile ? styles.iconMobile : null]}
                />
              </TouchableOpacity>
            </View>
            <View
              style={[
                styles.innerContainer,
                mobile ? styles.innerContainerMobile : null,
              ]}
            >
              <View
                style={[
                  styles.propertyColumn,
                  mobile ? styles.propertyColumnMobile : null,
                ]}
              >
                <Text styles={styles.titleMain}>
                  <Image
                    source={require("../assets/House_Gray.png")}
                    style={styles.icon}
                  />
                  <Text h1 style={styles.title}>
                    {property.name}
                  </Text>
                  <View style={styles.bookingHr} />
                </Text>
                <Text style={styles.address}>{property.address}</Text>
                <Image
                  source={{
                    uri: `data:image/png;base64, ${property.image_path}`,
                  }}
                  style={styles.image}
                />
                <Text style={styles.bookingLabel}>Booking Information:</Text>
                <View style={styles.feature}>
                  <Text style={styles.boxLabel}>
                    <Image
                      source={require("../assets/Calendar_Gray.png")}
                      style={styles.icon}
                    />
                    <Text style={styles.featureLabel}>
                      {new Date().getMonth() + 1}/{new Date().getDate()}/
                      {new Date().getFullYear()}
                    </Text>
                  </Text>
                  <Text style={styles.boxLabel}>
                    <Image
                      source={require("../assets/Clock_Gray.png")}
                      style={styles.icon}
                    />
                    <Text style={styles.featureLabel}>{`${formatTime(
                      new Date()
                    )} - ${formatTime(
                      new Date(new Date().getTime() + 7200000)
                    )}`}</Text>
                  </Text>
                  <Text style={styles.boxLabel}>
                    <Image
                      source={require("../assets/Purchase.png")}
                      style={styles.icon}
                    />
                    <Text style={styles.featureLabel}>{`${prettyPrintCents(
                      property.spots.sort((a, b) => a.cost - b.cost)[0].cost
                    )} / hour x 2 hours`}</Text>
                  </Text>
                  <View style={styles.bookingHr} />
                  <Text style={styles.priceLabel}>
                    {`Total: ${prettyPrintCents(
                      property.spots.sort((a, b) => a.cost - b.cost)[0].cost * 2
                    )}`}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.paymentColumn,
                  mobile ? styles.paymentColumnMobile : null,
                ]}
              >
                <Text styles={styles.titleMain}>
                  <Image
                    source={require("../assets/Purchase.png")}
                    style={styles.icon}
                  />
                  <Text h1 style={styles.title}>
                    Payment Information
                  </Text>
                  <View style={styles.bookingHr} />
                </Text>
                <Text style={styles.paymentLabel}>
                  Please enter your Credit Card information below:
                </Text>
                <View style={styles.doubleBox}>
                  <Text style={styles.boxLabel}>Credit Card Number: </Text>
                  <TextInput style={styles.textInput} />
                </View>
                <View
                  style={[
                    styles.boxesFlex,
                    mobile ? styles.boxFlexMobile : null,
                  ]}
                >
                  <View style={[styles.box, mobile ? styles.boxMobile : null]}>
                    <Text style={styles.boxLabel}>Expiration Month: </Text>
                    <TextInput style={styles.textInput} />
                  </View>
                  <View style={[styles.box, mobile ? styles.boxMobile : null]}>
                    <Text style={styles.boxLabel}>Expiration Year: </Text>
                    <TextInput style={styles.textInput} />
                  </View>
                  <View style={[styles.box, mobile ? styles.boxMobile : null]}>
                    <Text style={styles.boxLabel}>Security Code: </Text>
                    <TextInput style={styles.textInput} />
                  </View>
                </View>
                <Button
                  text="Reserve Spot Now"
                  onPress={() => {
                    global.api.reserve(property, this);
                  }}
                  imageSource={require("../assets/GO.png")}
                />
                <View style={styles.hr} />
                <Text style={styles.paymentLabel}>
                  or pay with another method
                </Text>
                <TouchableOpacity
                  style={[styles.grey, mobile ? styles.paddingLower : null]}
                >
                  <Text style={styles.buttonText}>Pay With </Text>
                  <Image
                    source={require("../assets/Paypal_Icon.png")}
                    style={styles.paypal}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </Animated.ScrollView>
        )}
      </AppContext.Consumer>
    );
  }
}
PaymentView.propTypes = {
  property: ProprtyShape,
};
PaymentView.defaultProps = {
  property: null,
};
export default PaymentView;
