import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "../components/router";
import Map from "../components/map";

import Search from "../components/search";
import { prettyPrintCents } from "../lib/costhandler";

import { View, StyleSheet, AsyncStorage } from "react-native";
import Text from "../components/ui/text";

import brand from "../assets/styles";
import AppContext from "../lib/context";

const styles = StyleSheet.create({
  popup: {
    backgroundColor: "transparent",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  popupText: {
    // fontWeight: 'bold',
    borderColor: "transparent",
    borderWidth: 2,
    borderBottomColor: brand.white,
    paddingBottom: 5,
    fontSize: 18,
    color: brand.darkGrey,
    textAlign: "center",
    width: "100%",
    alignItems: "center",
    flexBasis: 140,
  },
  popupTextPrice: {
    fontWeight: "bold",
    fontSize: 22,
  },
});
export default ({
  openProperty,
  viewSidebar,
  property,
  reservation,
  searchStyle,
}) => {
  const location = [30.266376, -97.744377];
  const [zoom, setZoom] = useState(16);
  const [properties, setProperties] = useState([]);
  const mapChange = () => { };
  const history = useHistory();
  const { state } = useContext(AppContext);

  useEffect(() => {
    const checkUser = async () => {
      const user = await AsyncStorage.getItem("user");
      if (!user || user == "null") return history.push("/profile");

      if (!state.user) return;
      setProperties(await global.api.fetchProperties());
    };
    checkUser();
  }, []);
  return (
    <View style={{ flex: 1, width: "100%", marginTop: 80 }}>
      <Map.parkingMap
        center={location}
        zoom={zoom}
        onChange={(change) => mapChange(change)}
      >
        <Map.parkingMarker
          position={location}
          popup={false}
          showPopup={zoom > 10}
        >
          <View style={styles.popup}>
            <Text h3 white style={styles.popupText}>
              You are here!
            </Text>
          </View>
        </Map.parkingMarker>
        {properties.map((s) => (
          <Map.parkingMarker
            position={s.location.split(",").map(parseFloat)}
            key={s.id}
            popup
            showPopup={zoom > 13}
            selected={viewSidebar && s.id === property.id}
            onView={() => openProperty(s)}
          >
            <View style={styles.popup} onView={() => openProperty(s)}>
              <Text style={styles.popupTextPrice}>
                {`${prettyPrintCents(
                  s.spots.sort((a, b) => a.cost - b.cost)[0].cost
                )}`}
              </Text>
            </View>
          </Map.parkingMarker>
        ))}
      </Map.parkingMap>
      <Search
        property={property}
        style={searchStyle}
        pointerEvents={property || reservation ? "none" : "all"}
      />
    </View>
  );
};
