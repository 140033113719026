import React, { useEffect, useState } from "react";
import { useParams } from "../../components/router";
import { View, StyleSheet } from "react-native";
import Text from "../../components/ui/text";
import TextInput from "../../components/ui/TextInput";
import Button from "../../components/ui/button";
import ImageInput from "../../components/ui/ImageInput";
import PropertyCard from "../../components/propertyCard";

const styles = StyleSheet.create({
  container: { marginTop: 100, alignItems: "center", justifyContent: "center" },
  form: {
    maxWidth: 600,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  input: {
    margin: 5,
  },
  image: {
    width: 50,
    height: 50,
  },
});

export default () => {
  let { id } = useParams();
  const [property, setProperty] = useState({});
  const [form, setForm] = useState({});
  if (!id)
    return (
      <View>
        <Text>No property!</Text>
      </View>
    );
  useEffect(() => {
    const getProperty = async () => {
      setProperty(await global.api.fetchProperty(id));
    };
    getProperty();
  }, []);
  const submit = () => {};
  const updateForm = (key, val) => {
    const oldForm = form;
    if (val == "") delete oldForm[key];
    else {
      oldForm[key] = val;
      console.log(oldForm);
    }
    setForm({ ...oldForm });
  };
  const changeAddress = () => {};
  console.log("loaded edit for", id);
  return (
    <View style={styles.container}>
      <Text h1>
        Editing {property.id} - {property.name}
      </Text>
      <View style={styles.form}>
        <TextInput
          onChangeText={(text) => {
            updateForm("name", text);
          }}
          placeholder={property.name}
          label="Name"
          style={styles.input}
        />
        <TextInput
          onChangeText={(text) => {
            updateForm("address", text);
          }}
          placeholder={property.address}
          label="Address"
          style={styles.input}
        />
        <ImageInput
          placeholder={`data:image/png;base64, ${property.image_path}`}
          style={{ flexBasis: "100%" }}
          onPick={(uri) => {
            setForm({ ...form, image: uri });
          }}
        />
        <Button text="Submit" onPress={submit} style={{ flexBasis: "100%" }} />
      </View>
      <View>
        <Text h1>Preview</Text>
        <PropertyCard property={{ ...property, ...form }} />
      </View>
    </View>
  );
};
