import React from "react";
import {
  StyleSheet,
  View,
  TextInput,
  TouchableOpacity,
  Image,
  Dimensions,
  Animated,
  Easing,
} from "react-native";
import ProprtyShape from "../prop-types/property";
import brand from "../assets/styles";
import Text from "./ui/text";

import AppContext from "../lib/context";

const debounce = (callback, wait, immediate) => {
  let timeout;
  return (...args) => {
    const callNow = immediate && !timeout;
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
    if (callNow) {
      next();
    }
  };
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: 80,
    bottom: 0,
    left: 0,
    backgroundColor: brand.grey,
    zIndex: 10,
  },
  box: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    margin: 5,
    height: 60,
    width: "75%",
  },
  boxLabel: {
    alignItems: "center",
    color: brand.grey,
    fontSize: 20,
  },
  boxWrapper: {
    flexDirection: "row",
    width: "30%",
  },
  divider: {
    borderColor: "transparent",
    borderWidth: 1,
    height: 80,
    borderRightColor: brand.darkGrey,
  },
  image: {
    width: 64,
    height: 64,
    marginHorizontal: 5,
  },
  results: {
    position: "absolute",
    bottom: 50,
    left: 0,
    backgroundColor: "white",
  },
  result: {
    padding: 5,
  },
  textInput: {
    padding: 0,
    height: 25,
    fontSize: 25,
    color: brand.darkGrey,
    width: "60%",
  },
  select: {
    backgroundColor: brand.grey,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    right: 0,
    padding: 0,
    height: 80,
    width: 380,
    borderColor: "transparent",
    borderWidth: 1,
    borderLeftColor: brand.darkGrey,
  },
  selectBlue: {
    backgroundColor: brand.blue,
    flexDirection: "row",
    alignItems: "center",
    right: 0,
    padding: 0,
    height: 80,
    width: 380,
  },
  selectText: {
    fontSize: 26,
    marginLeft: 10,
    flex: 1.5,
  },
  containerMobile: {
    flexDirection: "column",
    height: 315,
    zIndex: 5,
    backgroundColor: "transparent",
  },
  boxWrapperMobile: {
    borderColor: "transparent",
    borderWidth: 1,
    borderBottomColor: brand.darkGrey,
    backgroundColor: brand.grey,
    width: "100%",
    zIndex: 1,
  },
  dividerMobile: {
    display: "none",
  },
  selectMobile: {
    borderLeftColor: "transparent",
    width: "100%",
    //bottom: 4,
    height: 70,
  },
  selectBlueMobile: {
    width: "100%",
    //bottom: 4,
    height: 70,
  },
  selectTextMobile: {
    fontSize: 34,
  },
  hideSearchContainer: {
    flexDirection: "row-reverse",
  },
  hideSearch: {
    backgroundColor: brand.blue,
    width: 67,
    height: 29,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    justifyContent: "center",
    alignItems: "center",
  },
  animatedContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: Dimensions.get("window").width - 400,
    alignItems: "center",
    height: 80,
    bottom: 0,
    left: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeftWidth: 9,
    borderRightWidth: 9,
    borderBottomWidth: 0,
    borderTopWidth: 15,
    borderRightColor: "transparent",
    borderLeftColor: "transparent",
    borderBottomColor: brand.white,
    borderTopColor: brand.white,
  },
  triangle2: {
    width: 0,
    height: 0,
    borderLeftWidth: 9,
    borderRightWidth: 9,
    borderBottomWidth: 15,
    borderTopWidth: 0,
    borderRightColor: "transparent",
    borderLeftColor: "transparent",
    borderBottomColor: brand.white,
    borderTopColor: brand.white,
  },
});

function reserveClicked(state, setState) {
  setState({ ...state, payment: true, viewSidebar: false });
}

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      mobile: false,
      visible: false,
      bottom: new Animated.Value(0),
    };
  }

  componentDidMount() {
    if (Dimensions.get("window").width < 500) {
      this.setState({ mobile: true }, this.toggle);
    } else this.setState({ mobile: false });
  }

  updateLocationText = (text) => {
    debounce(async () => {
      let locations = await global.api.searchLocation(text);
      locations = locations.map((l) => {
        const displayName = l.display_name.split(",");
        const { 0: pre1, 1: pre2, [displayName.length - 1]: suf } = displayName;
        return { ...l, display_name: `${pre1},${pre2},${suf}` };
      });
      console.log(locations);
      this.setState({ locations });
    }, 500)();
  };

  toggle() {
    const { bottom, visible } = this.state;
    if (visible) {
      Animated.timing(bottom, {
        toValue: 220,
        easing: Easing.elastic(),
        duration: 300,
        useNativeDriver: true,
      }).start(() => this.setState({ visible: false }));
    } else {
      Animated.timing(bottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 300,
        useNativeDriver: true,
      }).start(() => this.setState({ visible: true }));
    }
  }

  selectLocation(state, setState, lat, lng) {
    setState({
      mapLocation: [parseFloat(lat), parseFloat(lng)],
    });
    this.setState({ locations: [] });
  }

  render() {
    const { locations, mobile, bottom, visible } = this.state;
    const { property, style } = this.props;
    return (
      <AppContext.Consumer>
        {({ state, setState }) => (
          <View
            style={[
              styles.container,
              mobile ? styles.containerMobile : null,
              style,
            ]}
          >
            <Animated.View
              pointerEvents={"box-none"}
              style={[
                !mobile ? styles.animatedContainer : null,
                { transform: [{ translateY: bottom }] },
              ]}
            >
              <View style={styles.hideSearchContainer}>
                <TouchableOpacity
                  style={mobile ? styles.hideSearch : { display: "none" }}
                  onPress={() => this.toggle()}
                >
                  <View
                    style={[styles.triangle, visible ? null : styles.triangle2]}
                  />
                </TouchableOpacity>
              </View>

              <View
                style={[
                  styles.boxWrapper,
                  mobile ? styles.boxWrapperMobile : null,
                ]}
                pointerEvents={visible ? "auto" : "box-only"}
              >
                <Image
                  source={require("../assets/Pin.png")}
                  style={styles.image}
                />
                <View style={styles.box}>
                  <Text style={styles.boxLabel}>Location: </Text>
                  <TextInput
                    style={styles.textInput}
                    // onChangeText={this.updateLocationText}
                    value="Austin, TX"
                  />
                </View>
              </View>
              <View
                style={[styles.divider, mobile ? styles.dividerMobile : null]}
              />
              <View style={styles.results}>
                {locations.map((l) => (
                  <TouchableOpacity
                    style={styles.result}
                    key={l.place_id}
                    onPress={() =>
                      this.selectLocation(state, setState, l.lat, l.lon)
                    }
                  >
                    <Text>{l.display_name}</Text>
                  </TouchableOpacity>
                ))}
              </View>
              <View
                style={[
                  styles.boxWrapper,
                  mobile ? styles.boxWrapperMobile : null,
                ]}
              >
                <Image
                  source={require("../assets/Calendar.png")}
                  style={styles.image}
                />
                <View style={styles.box}>
                  <Text style={styles.boxLabel}>Date: </Text>
                  <TextInput
                    style={styles.textInput}
                    // onChangeText={this.updateLocationText}
                    value={`${
                      new Date().getMonth() + 1
                    }/${new Date().getDate()}/${new Date().getFullYear()}`}
                  />
                </View>
              </View>
              <View
                style={[styles.divider, mobile ? styles.dividerMobile : null]}
              />
              <View
                style={[
                  styles.boxWrapper,
                  mobile ? styles.boxWrapperMobile : null,
                ]}
              >
                <Image
                  source={require("../assets/Clock.png")}
                  style={styles.image}
                />
                <View style={styles.box}>
                  <Text style={styles.boxLabel}>Time: </Text>
                  <TextInput
                    style={styles.textInput}
                    // onChangeText={this.updateLocationText}
                    value={`${
                      new Date().getHours() > 12
                        ? new Date().getHours() - 12
                        : new Date().getHours()
                    }:${`0${new Date().getMinutes()}`.slice(-2)} ${
                      new Date().getHours() >= 12 ? "PM" : "AM"
                    }`}
                  />
                </View>
              </View>
            </Animated.View>
            {!property ? (
              <TouchableOpacity
                style={[
                  styles.select,
                  mobile ? styles.selectMobile : { position: "absolute" },
                ]}
              >
                <Image
                  source={require("../assets/Pin.png")}
                  style={styles.image}
                />
                <Text
                  white
                  style={[
                    styles.selectText,
                    mobile ? styles.selectTextMobile : null,
                  ]}
                >
                  Select A Spot
                </Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={[
                  styles.selectBlue,
                  mobile ? styles.selectBlueMobile : { position: "absolute" },
                ]}
                onPress={() => reserveClicked(state, setState)}
              >
                <Image
                  source={require("../assets/GO.png")}
                  style={styles.image}
                />
                <Text
                  white
                  style={[
                    styles.selectText,
                    mobile ? styles.selectTextMobile : null,
                  ]}
                >
                  Reserve this Spot
                </Text>
              </TouchableOpacity>
            )}
          </View>
        )}
      </AppContext.Consumer>
    );
  }
}
Search.propTypes = {
  property: ProprtyShape,
};
Search.defaultProps = {
  property: null,
};
