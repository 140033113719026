import React from "react";
import { TextInput, View, StyleSheet } from "react-native";
import Text from "./text";
const styles = StyleSheet.create({
  label: { fontSize: 24, fontWeight: "bold" },
  input: {
    fontSize: 20,
    backgroundColor: "rgba(0,0,0,0.1)",
    padding: 5,
    borderRadius: 4,
  },
});

export default ({ onChangeText, placeholder, label, style }) => {
  return (
    <View style={style}>
      <Text style={styles.label}>{label}</Text>
      <TextInput
        style={styles.input}
        onChangeText={onChangeText}
        placeholder={placeholder}
      />
    </View>
  );
};
