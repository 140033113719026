/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Text, StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';

import brand from '../../assets/styles';

const styles = StyleSheet.create({
  text: {
    color: brand.darkGrey,
  },
  white: {
    color: brand.white,
  },
  light: {
    color: brand.grey,
  },
  dark: {
    color: brand.black,
  },
  highlight: {
    color: brand.blue,
  },
  underline: {
    textDecorationLine: 'underline',
  },
  center: {
    textAlign: 'center',
  },
  h1: {
    fontSize: 28,
    fontWeight: 'bold',
    color: brand.darkGrey,
    marginVertical: 5,
  },
  h2: {
    fontSize: 20,
  },
  h3: {
    fontSize: 18,
  },
  h4: {
    fontSize: 16,
  },
});

function generateTextStyle(props) {
  return Object.keys(props).reduce((o, k) => {
    if (!styles[k] || !props[k]) return o; return [...o, styles[k]];
  }, [styles.text]).concat(props.style);
}

const CText = (props) => {
  const { children } = props;
  return (
    <Text style={generateTextStyle(props)}>
      {children}
    </Text>
  );
};

CText.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.node,
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
};
CText.defaultProps = {
  children: '',
  h1: false,
  h2: false,
  h3: false,
  h4: false,
};

export default CText;
