import PropTypes from 'prop-types';

export default PropTypes.shape({
  address: PropTypes.string,
  image_path: PropTypes.string,
  owner: PropTypes.object,
  id: PropTypes.number,
  name: PropTypes.string,
  covered: PropTypes.number,
  gated: PropTypes.number,
  lit: PropTypes.number,
  spots: PropTypes.arrayOf(PropTypes.object),
  cameras: PropTypes.number,
  transit: PropTypes.number,
  charging: PropTypes.number,
  hourly_price: PropTypes.number,
  rating: PropTypes.number,
});
