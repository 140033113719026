import React, { useState, useContext } from "react";
import { View, StyleSheet, TextInput, ScrollView, Image, Dimensions } from "react-native";
import Text from "../components/ui/text";
import Button from "../components/ui/button";
import AppContext from "../lib/context";
import { useHistory } from "react-router-dom";
import brand from "../assets/styles";
import { setStatusBarTranslucent } from "expo-status-bar";

const { height, width } = Dimensions.get("window");

const styles = StyleSheet.create({
  container: {
    paddingTop: 90,
    alignItems: "center",
  },
  login: {
    width: 400,
    alignItems: "center",
  },
  logo: {
    width: 50,
    height: 50,
    marginHorizontal: 20,
    marginVertical: 10,
  },
  register: {
    width: 800,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    //display: "none",
  },
  loginHead: {
    height: 70,
    backgroundColor: brand.darkBlue,
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  title: {
    width: 100,
    color: brand.white,
    marginVertical: 15,
    fontWeight: "normal",
  },
  message: {
    color: brand.mediumGrey,
    marginTop: 10,
  },
  hr: {
    width: "80%",
    borderBottomColor: brand.darkGrey,
    borderBottomWidth: 3,
    marginVertical: 15,
  },
  box: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    margin: 5,
    borderColor: brand.darkGrey,
    borderWidth: 1,
    width: "80%",
  },
  boxLabel: {
    alignItems: "center",
    color: brand.grey,
    fontSize: 18,
  },
  textInput: {
    padding: 0,
    height: 20,
    fontSize: 20,
    color: brand.darkGrey,
    width: "65%",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    justifyContent: "space-between",
  },
  or: {
    marginTop: 35,
  },
  button: {
    textAlign: "center",
    width: "40%",
  },
  toRegister: {
    backgroundColor: brand.darkBlue,
  },
  boxHalf: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    margin: 5,
    borderColor: brand.darkGrey,
    borderWidth: 1,
    width: "45%",
    marginVertical: 20,
  },
  hrReg: {
    width: "95%",
    borderBottomColor: brand.darkGrey,
    borderBottomWidth: 3,
    marginVertical: 15,
  },
  usernameCond: {
    position: "absolute",
    top: 130,
    left: 125,
  },
  passCond: {
    position: "absolute",
    //top: 302, //if using F/L name
    top: 216,
    left: 32,
  },
  buttonsRegister: {
    display: "flex",
    flexDirection: "row",
    width: "95%",
    justifyContent: "space-between",
  },
  buttonRegisterBack: {
    textAlign: "center",
    width: "20%",
    backgroundColor: "#E14F4F",
  },
  buttonRegister: {
    textAlign: "center",
    width: "20%",
  },
  loginMobile: {
    width: "100%",
  },
  loginHeadMobile: {

  },
  boxMobile: {
    width: "90%",
  },
  buttonsMobile: {
    width: "90%",
  },
  buttonMobile: {
    fontSize: 20,
    width: "40%",
  },
  registerMobile: {
    width: "100%",
  },
  usernameCondMobile: {
    fontSize: 13,
    left: 100,
  },
  passCondMobile: {
    fontSize: 13,
    left: 20,
    top: 305,
    //top: 475, // if using F/L name
  },
});

const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [loginText, setLoginText] = useState("Login");
  const [registerText, setRegisterText] = useState("Register");
  const [onRegister, isRegister] = React.useState(false);
  const [mobile, setMobile] = React.useState(Dimensions.get("window").width < 500 ? true : false);
  const { state, setState } = useContext(AppContext);
  const history = useHistory();
  const login = async () => {
    setLoginText("Logging in...");
    const data = await global.api.login(username, password);
    if (!data) return;
    history.push("/");
  };
  const register = async () => {
    if (password !== password2)
      return setRegisterText("Passwords do not match!");
    setRegisterText("Registering...");
    const data = await global.api.register({
      username,
      email,
      first,
      last,
      password,
    });
    if (!data) return;
    history.push("/");
  };
  return (
    <ScrollView contentContainerStyle={styles.container}>
      {!onRegister ?
        <View style={[
          styles.login,
          mobile ? styles.loginMobile : null,
        ]
        }>
          <View style={[styles.loginHead,
          mobile ? styles.loginHeadMobile : null]}>
            <Image
              style={styles.logo}
              source={require("../assets/Logo_White.png")}
            />
            <Text h1 style={
              styles.title
            }>Login</Text>
          </View>
          <Text style={styles.message}>Please enter your account information below to login:</Text>
          <View style={[styles.hr, mobile ? styles.boxMobile : null]}></View>

          <View style={[styles.box, mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>User Name: </Text>

            <TextInput style={styles.textInput}
              autoCompleteType="username"
              onChangeText={setUsername}
            />
          </View>
          <View style={[styles.box, mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>Password: </Text>

            <TextInput style={styles.textInput}
              secureTextEntry
              autoCompleteType="password"
              onChangeText={setPassword}
            />
          </View>
          <View style={[styles.buttons, mobile ? styles.buttonsMobile : null]}>
            <Button text="Register" style={[styles.button, styles.toRegister,
            mobile ? styles.buttonMobile : null]} onPress={() => isRegister(!onRegister)} />
            <Text h3 style={styles.or}>OR</Text>
            <Button text={loginText} onPress={login} style={[styles.button,
            mobile ? styles.buttonMobile : null]} />
          </View>
        </View>
        :
        <View style={[
          styles.register, mobile ? styles.registerMobile : null]} >
          <View style={styles.loginHead}>
            <Image
              style={styles.logo}
              source={require("../assets/Logo_White.png")}
            />
            <Text h1 style={
              styles.title
            }>Register</Text>
          </View>

          <Text style={styles.message}>Please enter your account information below to register:</Text>
          <View style={[styles.hrReg, mobile ? styles.boxMobile : null]}></View>

          <Text style={[styles.usernameCond,
          mobile ? styles.usernameCondMobile : null]}>Maximum 16 Characters</Text>
          <View style={[styles.boxHalf,
          mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>User Name: </Text>

            <TextInput style={styles.textInput}
              autoCompleteType="username"
              onChangeText={setUsername}
            />
          </View>

          <View style={[styles.boxHalf,
          mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>Email: </Text>

            <TextInput style={styles.textInput}
              autoCompleteType="email"
              onChangeText={setEmail}
            />
          </View>

          {/* <View style={[styles.boxHalf,
          mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>First Name: </Text>

            <TextInput style={styles.textInput}
              onChangeText={setFirst} />
          </View>

          <View style={[styles.boxHalf,
          mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>Last Name: </Text>

            <TextInput style={styles.textInput} onChangeText={setLast} />
          </View> */}

          <Text style={[styles.passCond, mobile ? styles.passCondMobile : null]}>
            Minimum 8 characters, must have at least one number</Text>
          <View style={[styles.boxHalf,
          mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>Password: </Text>

            <TextInput style={styles.textInput}
              secureTextEntry
              autoCompleteType="password"
              onChangeText={setPassword}
            />
          </View>

          <View style={[styles.boxHalf,
          mobile ? styles.boxMobile : null]}>
            <Text style={styles.boxLabel}>Re-enter: </Text>

            <TextInput style={styles.textInput}
              secureTextEntry
              autoCompleteType="password"
              onChangeText={setPassword2}
            />
          </View>
          <View style={[styles.buttonsRegister, mobile ? styles.buttonsMobile : null]}>
            <Button text="Back" style={[styles.buttonRegisterBack, mobile ? styles.buttonMobile : null]} onPress={() => isRegister(!onRegister)} />
            <Button text={registerText} onPress={register} style={[styles.buttonRegister, mobile ? styles.buttonMobile : null]} />
          </View>
        </View>
      }
    </ScrollView >
  );
};

const ProfileScreen = () => {
  const logout = async () => {
    await global.api.logout();
  };
  return (
    <View style={styles.container}>
      <Text h1>Profile</Text>
      <Button onPress={logout} text="Logout"></Button>
    </View>
  );
};

export default () => {
  const { state } = useContext(AppContext);
  return <View>{state.user ? <ProfileScreen /> : <LoginScreen />}</View>;
};
