import React, { useContext, useEffect, useState } from "react";
import Text from "../../components/ui/text";
import { StyleSheet, ScrollView, View } from "react-native";
import Button from "../../components/ui/button";
import AppContext from "../../lib/context";
import PropertyCard from "../../components/propertyCard";
const styles = StyleSheet.create({
  container: {
    marginTop: 100,
    maxHeight: 600,
  },
});
export default () => {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const getProperties = async () => {
      const loadedProperties = await global.api.fetchProperties(true);
      setProperties(loadedProperties);
    };
    getProperties();
  }, []);
  return (
    <View style={[styles.container]}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {properties.map((p) => (
          <PropertyCard key={p.id} property={p} />
        ))}
      </ScrollView>
      <Button text="Create Property" />
    </View>
  );
};
