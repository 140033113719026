import React from 'react';
import {
  View, StyleSheet, Image, Dimensions,
} from 'react-native';
import Text from './ui/text';
import ReservationShape from '../prop-types/reservation';
import brand from '../assets/styles';
import { formatDate, formatTime, formatRemaining } from '../lib/timehandler';


const styles = StyleSheet.create({
  timer: {
    backgroundColor: brand.lightGrey,
    borderColor: brand.grey,
    borderWidth: 8,
    alignItems: 'center',
    paddingHorizontal: 60,
    paddingVertical: 15,
    marginHorizontal: 40,
  },
  timeText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',

  },
  timeLabel: {
    marginHorizontal: 25,
    fontSize: 28,
  },
  timeOn: {
    fontSize: 28,
    marginHorizontal: 10,
  },
  timeValue: {
    fontSize: 28,
  },
  timeRemaining: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  clock: {
    width: 58,
    height: 58,
    marginRight: 10,
  },
  hr: {
    width: '100%',
    borderBottomColor: brand.darkGrey,
    borderBottomWidth: 3,
    marginVertical: 25,
  },
  clockText: {
    fontSize: 100,
  },
  clockLabel: {
    fontSize: 30,
  },
  timerMobile: {
    width: '100%',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  timeLabelMobile: {
    marginHorizontal: 5,
    fontSize: 18,
  },
  timeOnMobile: {
    fontSize: 18,
    marginHorizontal: 10,
  },
  timeValueMobile: {
    fontSize: 18,
  },
  clockTextMobile: {
    fontSize: 60,
  },
  clockLabelMobile: {
    fontSize: 18,
  },
});

class timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: new Date(),
      mobile: false,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ currentTime: new Date() }), 1000);
    if (Dimensions.get('window').width < 500) this.setState({ mobile: true });
    else this.setState({ mobile: false });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { reservation } = this.props;
    const { currentTime, mobile } = this.state;
    return (
      <View style={[styles.timer, mobile ? styles.timerMobile : null]}>
        <View style={styles.timeText}>
          <Text style={[styles.timeLabel, mobile ? styles.timeLabelMobile : null]}>Current Time:</Text>
          <Text light>
            <Text light underline style={[styles.timeValue, mobile ? styles.timeValueMobile : null]}>{formatTime(currentTime)}</Text>
            <Text light style={[styles.timeOn, mobile ? styles.timeOnMobile : null]}>on</Text>
            <Text light underline style={[styles.timeValue, mobile ? styles.timeValueMobile : null]}>{formatDate(currentTime)}</Text>
          </Text>
        </View>
        <View style={styles.timeText}>
          <Text highlight style={[styles.timeLabel, mobile ? styles.timeLabelMobile : null]}>Valid Until:</Text>
          <Text>
            <Text light underline style={[styles.timeValue, mobile ? styles.timeValueMobile : null]}>{formatTime(reservation.end)}</Text>
            <Text light style={[styles.timeOn, mobile ? styles.timeOnMobile : null]}>on</Text>
            <Text light underline style={[styles.timeValue, mobile ? styles.timeValueMobile : null]}>{formatDate(reservation.end)}</Text>
          </Text>
        </View>
        <View style={styles.hr} />
        <View style={styles.timeRemaining}>
          <Image source={require('../assets/Clock_Gray.png')} style={styles.clock} />
          <Text h2 style={mobile ? { fontSize: 26 } : { fontSize: 35 }}>Time Remaining: </Text>
        </View>
        <Text highlight style={[styles.clockText, mobile ? styles.clockTextMobile : null]}>
          {formatRemaining(reservation.end)}
        </Text>
        <Text style={[styles.clockLabel, mobile ? styles.clockLabelMobile : null]} light>
          Hours     Minutes        Seconds
        </Text>
      </View>
    );
  }
}
timer.propTypes = {
  reservation: ReservationShape.isRequired,
};
export default timer;
