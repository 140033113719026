import React, { useContext } from "react";
import {
  StyleSheet,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  Linking,
} from "react-native";
import Text from "./ui/text";
import Brand from "../assets/styles";
import { useHistory } from "./router";
import AppContext from "../lib/context";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
    position: "absolute",
    width: "100%",
    height: 80,
    top: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: Brand.darkBlue,
    opacity: 0.9,
  },
  user: {
    flexDirection: "row",
    right: 20,
    alignItems: "center",
  },
  center: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: 64,
    height: 64,
    marginHorizontal: 10,
  },
  logo: {
    width: 55,
    height: 55,
    marginHorizontal: 10,
  },
  bigText: {
    paddingVertical: 5,
    marginBottom: 5,
    fontSize: 30,
  },
  name: {
    color: Brand.white,
    paddingVertical: 5,
    marginBottom: 5,
    fontSize: 30,
  },
  centerMobile: {
    position: "absolute",
    left: 0,
    display: "none",
  },
  userMobile: {
    position: "absolute",
    right: 10,
  },
  iconMobile: {
    marginHorizontal: 0,
  },
  hideMobile: {
    display: "none",
  },
});

export default () => {
  const history = useHistory();
  const { state, setState } = useContext(AppContext);
  const canHost =
    state.user &&
    (state.user.account_type == 1 || state.user.account_type == 3);
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.center,
          Dimensions.get("window").width < 500 ? styles.centerMobile : null,
        ]}
      >
        <Image
          style={styles.logo}
          source={require("../assets/Logo_White.png")}
        />
        <Text white style={[styles.bigText, styles.name]}>
          Park Shark
        </Text>
      </View>
      <View
        style={[
          styles.user,
          Dimensions.get("window").width < 500 ? styles.userMobile : null,
        ]}
      >
        <Image
          style={[
            styles.icon,
            Dimensions.get("window").width < 500 ? styles.hideMobile : null,
          ]}
          source={require("../assets/Help.png")}
        />
        {canHost ? (
          <TouchableOpacity
            onPress={() => history.push("/host")}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <Image
              style={[
                styles.icon,
                Dimensions.get("window").width < 500 ? styles.hideMobile : null,
              ]}
              source={require("../assets/House_White.png")}
            />
            <Text
              white
              style={[
                styles.bigText,
                Dimensions.get("window").width < 500
                  ? { display: "none" }
                  : null,
              ]}
            >
              Host Dashboard
            </Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            onPress={() => Linking.openURL("https://parkshark.app/#contact")}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            {/* <Image
              style={[
                styles.icon,
                Dimensions.get("window").width < 500 ? styles.hideMobile : null,
              ]}
              source={require("../assets/House_White.png")}
            /> */}
            <Text
              white
              style={[
                styles.bigText,
                Dimensions.get("window").width < 500
                  ? { display: "none" }
                  : null,
              ]}
            >
              Become a Host
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={() => history.push("/profile")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Image
            style={[
              styles.icon,
              Dimensions.get("window").width < 500 ? styles.iconMobile : null,
            ]}
            source={require("../assets/profile.png")}
          />
          <Text
            white
            style={[
              styles.bigText,
              Dimensions.get("window").width < 500 ? { display: "none" } : null,
            ]}
          >
            {state.user ? state.user.username : "Login"}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
