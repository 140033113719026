import React from "react";
import {
  View,
  Image,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  Animated,
  Easing,
} from "react-native";
import ReservationShape from "../prop-types/reservation";
import brand from "../assets/styles";
import Button from "./ui/button";
import Text from "./ui/text";

import Timer from "./timer";

import AppContext from "../lib/context";
import Map from "../components/map";

const { height, width } = Dimensions.get("window");

const styles = StyleSheet.create({
  reservationContainer: {
    position: "absolute",
    zIndex: 15,
    right: "auto",
    height: height - 200,
    width: width - 200,
    maxWidth: 1200,
    maxHeight: 795,
    backgroundColor: brand.white,
    borderWidth: 1,
    borderColor: brand.grey,
    flexDirection: "row",
    flexWrap: "wrap",
    alignSelf: "center",
  },
  header: {
    height: 70,
    width: "100%",
    backgroundColor: brand.darkBlue,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  headerMobile: {
    display: "none",
  },
  logo: {
    width: 55,
    height: 55,
    marginHorizontal: 10,
  },
  name: {
    color: brand.white,
    paddingVertical: 5,
    marginBottom: 5,
    fontSize: 30,
    flex: 0.99,
  },
  innerContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  innerContainerMobile: {
    flexDirection: "column-reverse",
    height: "100%",
  },
  propertyColumn: {
    paddingTop: 0,
    padding: 10,
    paddingRight: 80,
    paddingLeft: 110,
  },
  propertyTitle: {
    fontSize: 35,
  },
  propertyTitleContainer: {
    alignItems: "center",
    flexDirection: "row",
    position: "relative",
    top: -10,
  },
  hr: {
    width: "100%",
    borderBottomColor: brand.darkGrey,
    borderBottomWidth: 3,
    marginVertical: 15,
  },
  timeColumn: {
    flex: 1,
    alignItems: "center",
    borderLeftColor: brand.darkGrey,
    borderLeftWidth: 3,
    marginBottom: 30,
    paddingBottom: 0,
  },
  icon: {
    width: 47,
    height: 47,
    marginRight: 10,
  },
  blank: {
    flex: 1,
  },
  close: {
    height: 64,
    top: 8,
    right: -12,
  },
  titleMain: {
    fontSize: 28,
    color: brand.darkGrey,
    paddingVertical: 5,
    marginBottom: 5,
  },
  reservationContainerMobile: {
    width: "100%",
    height: height - 80,
    flexDirection: "column-reverse",
    flexWrap: "nowrap",
    top: 20,
  },
  timeColumnMobile: {
    width: "99%",
    top: 20,
    borderLeftColor: "transparent",
  },
  titleMobile: {
    fontSize: 28,
    textAlign: "center",
  },
  closeMobile: {
    alignSelf: "flex-end",
  },
  titleColumnMobile: {
    display: "none",
  },
  titleColumnMobileReplace: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  propertyColumnMobile: {
    width: "100%",
    top: 60,
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 40,
  },
  iconMobile: {
    height: 57,
    width: 57,
  },
});

class ReservationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { top: new Animated.Value(-500), addition: 0, mobile: false };
  }

  componentDidMount() {
    if (Dimensions.get("window").width < 500) this.setState({ mobile: true });
    else this.setState({ mobile: false });
  }

  show() {
    const { top, mobile } = this.state;
    if (mobile) {
      Animated.timing(top, {
        toValue: 80,
        easing: Easing.elastic(),
        duration: 300,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(top, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  }

  hide(state, setState) {
    const { top } = this.state;
    Animated.timing(top, {
      toValue: -1000,
      easing: Easing.elastic(),
      duration: 300,
      useNativeDriver: false,
    }).start(() => setState({ ...state, reservation: false, property: null }));
  }

  render() {
    const { reservation } = this.props;
    const { property } = reservation;
    const { top, addition, mobile } = this.state;
    if (!reservation) return null;
    this.show();
    return (
      <AppContext.Consumer>
        {({ state, setState }) => (
          <Animated.ScrollView
            style={[
              styles.reservationContainer,
              mobile ? styles.reservationContainerMobile : null,
              { top },
            ]}
          >
            <View style={[styles.header, mobile ? styles.headerMobile : null]}>
              <Image
                style={styles.logo}
                source={require("../assets/Pin.png")}
              />
              <Text white style={styles.name}>
                Current Reservation
              </Text>
              <TouchableOpacity
                style={[styles.close, mobile ? styles.closeMobile : null]}
                onPress={() => this.hide(state, setState)}
              >
                <Image
                  source={require("../assets/X.png")}
                  style={styles.icon}
                />
              </TouchableOpacity>
            </View>
            <View
              style={[
                styles.innerContainer,
                mobile ? styles.innerContainerMobile : null,
              ]}
            >
              <View
                style={[
                  styles.propertyColumn,
                  mobile ? styles.propertyColumnMobile : null,
                ]}
              >
                <View style={styles.propertyTitleContainer}>
                  <Image
                    source={require("../assets/House_Gray.png")}
                    style={styles.icon}
                  />
                  <Text style={styles.propertyTitle}>{property.name}</Text>
                </View>
                <View style={styles.hr} />
                <Text underline h2 center>
                  {property.address}
                </Text>
                <View style={{ width: "100%", height: 250, marginTop: 20 }}>
                  <Map.parkingMap
                    attribution={false}
                    center={property.location.split(",").map(parseFloat)}
                    zoom={17}
                  >
                    <Map.parkingMarker
                      position={property.location.split(",").map(parseFloat)}
                      popup={false}
                    >
                      <View style={styles.popup}>
                        <Text h3 white style={styles.popupText}>
                          Property Location
                        </Text>
                      </View>
                    </Map.parkingMarker>
                  </Map.parkingMap>
                </View>
                <Button
                  text="Find My Car"
                  imageSource={require("../assets/Search.png")}
                />
              </View>
              <View
                style={[
                  styles.timeColumn,
                  mobile ? styles.timeColumnMobile : null,
                ]}
              >
                <Timer
                  reservation={{
                    ...reservation,
                    end: new Date(reservation.end.getTime() + addition),
                  }}
                />
                <Button
                  style={{ top: 18, position: "relative", width: 344 }}
                  text="Add More time"
                  onPress={() =>
                    this.setState({ addition: addition + 3600000 })
                  }
                  imageSource={require("../assets/GO.png")}
                />
              </View>
              <View
                style={
                  mobile ? styles.titleColumnMobileReplace : { display: "none" }
                }
              >
                <View style={styles.blank} />
                <Text style={[styles.title, styles.titleMobile]}>
                  Current Reservation
                </Text>
                <TouchableOpacity
                  style={styles.closeMobile}
                  onPress={() => this.hide(state, setState)}
                >
                  <Image
                    source={require("../assets/X.png")}
                    style={[styles.icon, mobile ? styles.iconMobile : null]}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </Animated.ScrollView>
        )}
      </AppContext.Consumer>
    );
  }
}
ReservationView.propTypes = {
  reservation: ReservationShape,
};
ReservationView.defaultProps = {
  reservation: null,
};
export default ReservationView;
