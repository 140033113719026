import { AsyncStorage } from "react-native";
export default class Api {
  constructor(endpointBase, token, state, setState) {
    this.state = state;
    this.setState = setState;
    this.endpointBase = endpointBase;
    this.token = token;
    console.log(
      `Initializing API using endpoint: [${endpointBase}] and token: [${token}]`
    );
    console.log(process.env);
  }
  alert(message, type) {
    this.setState({ ...this.state, alert: { type, message, visible: true } });
    return false;
  }
  updateState(state, setState) {
    this.state = state;
    this.setState = setState;
  }
  async fetchData(endpoint, cache = true) {
    const url = `${this.endpointBase}${endpoint}`;
    const token = this.state.user.token.plainTextToken;

    try {
      const rawResponse = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
        redirect: "manual",
      });
      if (rawResponse.status == 0)
        return this.alert(
          "User invalid! Please logout and log back in.",
          "error"
        );
      if (rawResponse.status.toString()[0] != 2)
        return this.alert(
          await (await rawResponse.text()).slice(0, 500),
          "error"
        );
      const data = await rawResponse.json();
      console.info("Fetch " + endpoint, data);
      return data;
    } catch (e) {
      console.error("Error fetching", e);
      return this.alert(e.toString(), "error");
    }
  }

  async postData(endpoint, body) {
    const url = `${this.endpointBase}${endpoint}`;
    try {
      const rawResponse = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
      });
      if (rawResponse.status !== 200)
        return this.alert(await rawResponse.text(), "error");
      const data = await rawResponse.json();
      this.alert(data.message, "success");
      return data;
    } catch (e) {
      console.error(e);
      return this.alert(e.toString(), "error");
    }
  }

  async fetchText(endpoint) {
    const url = `${this.endpointBase}${endpoint}`;
    try {
      const rawResponse = await fetch(url);
      if (rawResponse.status !== 200) return await rawResponse.text();
      return rawResponse.text();
    } catch (e) {
      return this.alert(e.toString(), "error");
    }
  }

  async fetchProperties(mine = false) {
    let data;
    if (mine) data = await this.fetchData("/myproperties");
    else data = await this.fetchData("/properties");
    if (!data) return [];
    return data;
  }
  async fetchProperty(id) {
    return await this.fetchData("/properties/" + id);
  }

  async reserve(property, component) {
    const now = new Date();
    const end = new Date();
    end.setHours(end.getHours() + 2);
    const reservation = { property, start: now, end };
    this.setState({ reservation }, () => {
      if (component) component.hide();
    });
    return this.endpointBase; // Dummy return
  }

  async searchLocation(string) {
    const url = `https://nominatim.openstreetmap.org/search?q=${string}&format=json&limit=5`;
    try {
      const rawResponse = await fetch(url);
      if (rawResponse.status !== 200)
        return this.alert(await rawResponse.text(), "error");
      return rawResponse.json();
    } catch (e) {
      return this.alert(e.toString(), "error");
    }
  }

  async login(username, password) {
    const data = await this.postData("/login", { username, password });
    this.setState({ ...this.state, user: data.data });
    await AsyncStorage.setItem("user", JSON.stringify(data.data));
    return data;
  }
  async register(postBody) {
    const data = await this.postData("/register", postBody);
    this.setState({ ...this.state, user: data.data });
    await AsyncStorage.setItem("user", JSON.stringify(data.data));
    return data;
  }
  async logout() {
    await AsyncStorage.removeItem("user");
    this.setState({ ...this.state, user: null });
    console.log("logged out", this.state);
  }
  async fetchUser() {
    if (!this.state.user) return null;
    return await this.fetchData("/user");
  }
}
