import React, { useState } from "react";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import { View, Image, StyleSheet, Platform } from "react-native";
import Button from "./button";
import Text from "./text";
const styles = StyleSheet.create({
  image: {
    width: 250,
    height: 250,
  },
});
export default ({ placeholder, name, onPick, style }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const readFile = async () => {
    const { uri } = await DocumentPicker.getDocumentAsync();
    let imageUri = "";
    if (Platform.OS == "web") {
      imageUri = uri;
    } else {
      imageUri = await FileSystem.readAsStringAsync(uri);
    }
    setImagePreview(imageUri);
    onPick(imageUri);
  };
  const uploadFile = (uri) => {};
  return (
    <View style={style}>
      <Text h1>Image</Text>
      <Image
        style={styles.image}
        source={{ uri: imagePreview || placeholder }}
      />
      <Button text="Open Image" onPress={readFile} />
    </View>
  );
};
