/* eslint-disable react/no-unused-prop-types */
import React from "react";
import { TouchableOpacity, Text, Image, StyleSheet } from "react-native";
import PropTypes from "prop-types";

import brand from "../../assets/styles";

const styles = StyleSheet.create({
  button: {
    backgroundColor: brand.blue,
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    marginVertical: 20,
  },
  buttonText: {
    fontSize: 26,
    flex: 1.5,
    color: brand.white,
  },
  grey: {
    backgroundColor: brand.grey,
  },
  icon: {
    width: 60,
    height: 60,
    marginHorizontal: 5,
  },
});

function generateButtonStyle(props) {
  const style = [styles.button];
  if (props.grey) style.push(styles.grey);
  style.push(props.style);
  return style;
}

const Button = (props) => {
  const { imageSource, text, onPress } = props;
  return (
    <TouchableOpacity style={generateButtonStyle(props)} onPress={onPress}>
      {imageSource ? <Image source={imageSource} style={styles.icon} /> : null}
      <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
  );
};

Button.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  imageSource: PropTypes.any,
  text: PropTypes.string,
  onPress: PropTypes.func,
  grey: PropTypes.bool,
};
Button.defaultProps = {
  imageSource: null,
  text: "",
  onPress: () => {},
  grey: false,
};

export default Button;
