import React from "react";
import { View, StyleSheet, TouchableOpacity, Image } from "react-native";
import Text from "./ui/text";
import brand from "../assets/styles";
import { useHistory } from "react-router-dom";
const styles = StyleSheet.create({
  card: {
    backgroundColor: brand.darkBlue,
    margin: 10,
    padding: 10,
    elevation: 5,
  },
  rating: {
    marginBottom: 10,

    backgroundColor: brand.white,
  },
  ratingText: {
    top: -10,
    fontSize: 17,
  },
  ratingStarWrapper: {
    flexDirection: "row",
    // display: 'inline-flex',
  },
  ratingStar: {
    flexDirection: "row",
    height: 38,
    width: 38,
  },
});
export default ({ property }) => {
  const history = useHistory();
  return (
    <TouchableOpacity
      style={styles.card}
      onPress={() => history.push(`/property/edit/${property.id}`)}
    >
      <Text h1 white>
        {property.name}
      </Text>
      <Text white>{property.address}</Text>
      <View style={[{ flexDirection: "row" }, styles.rating]}>
        <Text style={styles.ratingText}>Rating:</Text>
        <View style={styles.ratingStarWrapper}>
          <Image
            source={require("../assets/Star.png")}
            style={styles.ratingStar}
          />
          <Image
            source={require("../assets/Star.png")}
            style={styles.ratingStar}
          />
          <Image
            source={require("../assets/Star.png")}
            style={styles.ratingStar}
          />
          <Image
            source={require("../assets/Star.png")}
            style={styles.ratingStar}
          />
          <Image
            source={require("../assets/Star_Gray.png")}
            style={styles.ratingStar}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};
