import React from "react";
import {
  View,
  Image,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  Animated,
  Easing,
} from "react-native";
import ProprtyShape from "../prop-types/property";
import brand from "../assets/styles";
import Text from "./ui/text";
import { prettyPrintCents } from "../lib/costhandler";

import AppContext from "../lib/context";

const blurb = () => ({
  width: 30,
  height: 30,
  borderRadius: 15,
  marginRight: 4,
  borderColor: brand.darkGrey,
  borderWidth: 2.4,
  top: -2,
});
const { height } = Dimensions.get("window");
const styles = StyleSheet.create({
  container: {
    position: "absolute",
    zIndex: 10,
    right: 0,
    width: 380,
    top: 80,
    backgroundColor: brand.lightGrey,
    alignItems: "center",
    borderWidth: 1,
    borderColor: brand.grey,
    height: height - 160,
  },
  heightLimit: {
    position: "absolute",
    zIndex: 10,
    right: -1,
    width: 380,
    backgroundColor: brand.white,
    alignItems: "center",
    maxHeight: 1500,
    borderColor: brand.grey,
    borderLeftWidth: 1,
  },
  title: {
    fontSize: 20,
    color: brand.darkGrey,
    borderColor: "transparent",
    borderBottomColor: brand.darkGrey,
    borderWidth: 1,
    paddingVertical: 5,
    marginBottom: 5,
  },
  rating: {
    flex: 0.1,
    marginBottom: 10,
  },
  ratingText: {
    top: -10,
    fontSize: 17,
  },
  ratingStarWrapper: {
    flexDirection: "row",
    // display: 'inline-flex',
  },
  ratingStar: {
    flexDirection: "row",
    height: 38,
    width: 38,
  },
  address: {
    textDecorationLine: "underline",
    fontSize: 16,
    fontWeight: "normal",
    width: 300,
    flex: 0.2,
    textAlign: "center",
    justifyContent: "center",
    color: brand.mediumGrey,
  },
  icon: {
    width: 64,
    height: 64,
    marginHorizontal: 5,
    top: -6,
  },
  image: {
    width: 280,
    height: 170,
    borderColor: brand.grey,
    borderWidth: 1.5,
  },
  spotInfo: {
    flexDirection: "row",
    borderColor: "transparent",
    borderWidth: 2,
    paddingBottom: 5,
    marginBottom: 5,
  },
  spotsLeft: {
    fontSize: 20,
    color: brand.darkGrey,
  },
  spotsAt: {
    fontSize: 18,
    color: brand.mediumGrey,
  },
  spotsPrice: {
    fontSize: 20,
    color: brand.darkGrey,
  },
  featuresLabel: {
    fontSize: 20,
    color: brand.darkGrey,
    width: "100%",
    textAlign: "center",
    backgroundColor: brand.lightGrey,
    borderTopColor: brand.grey,
    borderTopWidth: 1,
    paddingVertical: 5,
  },
  featuresContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingHorizontal: 10,
    flex: 0.4,
    paddingTop: 10,
    backgroundColor: brand.lightGrey,
  },
  feature: {
    paddingLeft: 10,
    display: "flex",
    margin: 2,
    flexBasis: "40%",
    flex: 1,
    color: brand.mediumGrey,
    fontSize: 17,
  },
  titleMain: {
    fontSize: 28,
    color: brand.darkGrey,
    paddingVertical: 5,
    marginBottom: 5,
  },
  titleFlex: {
    // display: 'inline-flex',
    flexDirection: "row",
    marginTop: -10,
  },
  xButton: {
    height: 64,
    right: -260,
  },
  Hr: {
    borderColor: "transparent",
    borderWidth: 2,
    borderBottomColor: brand.darkGrey,
    minWidth: "90%",
  },
  blurbYellow: {
    backgroundColor: "#FFDD00",
  },
  blurbOrange: {
    backgroundColor: "#E18E4F",
  },
  blurbBlue: {
    backgroundColor: "#4382FF",
  },
  blurbGrey: {
    backgroundColor: "#BBBBBB",
  },
  containerMobile: {
    position: "absolute",
    right: 0,
    backgroundColor: brand.lightGrey,
    alignItems: "center",
    borderWidth: 1,
    borderColor: brand.grey,
    width: "100%",
    zIndex: 10,
    bottom: 70,
    height: 430,
  },
  iconMobile: {
    height: 57,
    width: 57,
    top: 0,
  },
  heightLimitMobile: {
    width: "100%",
    right: 0,
    borderLeftWidth: 0,
  },
  detailsMobile: {
    flexDirection: "row",
    borderColor: "transparent",
    borderWidth: 2,
    borderBottomColor: brand.darkGrey,
    marginTop: 5,
    justifyContent: "space-around",
    width: "100%",
  },
  ratingHidden: {
    display: "none",
  },
  ratingHide: {
    display: "none",
  },
  ratingShow: {
    display: "flex",
    alignSelf: "center",
  },
  imageMobile: {
    width: 202,
    height: 134,
  },
  detailsColumnMobile: {
    flexDirection: "column",
  },
  spotsMobile: {
    flexDirection: "column",
    textAlign: "center",
    borderBottomColor: "transparent",
  },
  titleMainMobile: {
    fontSize: 23,
    alignSelf: "center",
  },
  addressMobile: {
    fontSize: 14,
  },
  titleWrapper: {
    // display: "contents",
  },
  titleWrapperMobile: {
    width: "100%",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  titleFlexMobile: {
    marginTop: 0,
  },
  xButtonMobile: {
    right: 0,
  },
  ratingStarM: {
    flexDirection: "row",
    height: 27,
    width: 27,
  },
  greyBox: {
    backgroundColor: brand.lightGrey,
    width: "100%",
  },
  greyBoxHr: {
    borderColor: "transparent",
    borderWidth: 2,
    borderBottomColor: brand.darkGrey,
    width: "90%",
    alignSelf: "center",
  },
});
class PropertyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { right: new Animated.Value(-300), mobile: false };
  }

  componentDidMount() {
    if (Dimensions.get("window").width < 500) this.setState({ mobile: true });
    else this.setState({ mobile: false });
  }

  componentDidUpdate(prevProps) {
    const { property } = this.props;
    if (!property || !prevProps.property) return;
    if (property.id !== prevProps.property.id) {
      this.refresh();
    }
  }

  show() {
    const { right } = this.state;
    Animated.timing(right, {
      toValue: 0,
      easing: Easing.elastic(),
      duration: 300,
      useNativeDriver: false,
    }).start();
  }

  hide(state, setState) {
    const { right } = this.state;
    Animated.timing(right, {
      toValue: -1000,
      easing: Easing.elastic(),
      duration: 300,
      useNativeDriver: false,
    }).start(() => setState({ ...state, viewSidebar: false, property: null }));
  }

  refresh() {
    const { right } = this.state;
    const slideOut = Animated.timing(right, {
      toValue: -600,
      easing: Easing.elastic(),
      duration: 400,
    });
    const slideIn = Animated.timing(right, {
      toValue: 0,
      easing: Easing.elastic(),
      duration: 400,
    });
    Animated.sequence([slideOut, slideIn]).start();
  }

  render() {
    const { property } = this.props;
    const { right, mobile } = this.state;
    if (!property) return null;
    this.show();
    return (
      <AppContext.Consumer>
        {({ state, setState }) => (
          <Animated.View
            style={[
              mobile ? styles.containerMobile : styles.container,
              { right },
            ]}
          >
            <View
              style={[
                styles.heightLimit,
                mobile ? styles.heightLimitMobile : null,
              ]}
            >
              <View
                style={mobile ? styles.titleWrapperMobile : styles.titleWrapper}
              >
                <TouchableOpacity
                  style={[styles.xButton, mobile ? styles.xButtonMobile : null]}
                  onPress={() => this.hide(state, setState)}
                >
                  <Image
                    source={require("../assets/X.png")}
                    style={[styles.icon, mobile ? styles.iconMobile : null]}
                  />
                </TouchableOpacity>
                <View
                  style={[
                    styles.titleFlex,
                    mobile ? styles.titleFlexMobile : null,
                  ]}
                >
                  <Image
                    source={require("../assets/House_Gray.png")}
                    style={[styles.icon, mobile ? styles.iconMobile : null]}
                  />
                  <Text
                    style={[
                      styles.titleMain,
                      mobile ? styles.titleMainMobile : null,
                    ]}
                  >
                    {property.name}
                  </Text>
                </View>
              </View>
              <View style={styles.Hr} />
              <Text
                style={[styles.address, mobile ? styles.addressMobile : null]}
              >
                {property.address}
              </Text>
              <View style={mobile ? styles.detailsMobile : null}>
                <View
                  style={[
                    { flexDirection: "row" },
                    styles.rating,
                    mobile ? styles.ratingHide : null,
                  ]}
                >
                  <Text style={styles.ratingText}>Rating:</Text>
                  <View style={styles.ratingStarWrapper}>
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStar}
                    />
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStar}
                    />
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStar}
                    />
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStar}
                    />
                    <Image
                      source={require("../assets/Star_Gray.png")}
                      style={styles.ratingStar}
                    />
                  </View>
                </View>
                <Image
                  source={{
                    uri: `data:image/png;base64, ${property.image_path}`,
                  }}
                  style={[styles.image, mobile ? styles.imageMobile : null]}
                />
                <View style={mobile ? styles.detailsColumnMobile : null}>
                  <Text
                    style={[
                      styles.ratingText,
                      styles.ratingHidden,
                      mobile ? styles.ratingShow : null,
                    ]}
                  >
                    Rating:
                  </Text>
                  <View
                    style={[
                      styles.ratingStarWrapper,
                      styles.ratingHidden,
                      mobile ? styles.ratingShow : null,
                    ]}
                  >
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStarM}
                    />
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStarM}
                    />
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStarM}
                    />
                    <Image
                      source={require("../assets/Star.png")}
                      style={styles.ratingStarM}
                    />
                    <Image
                      source={require("../assets/Star_Gray.png")}
                      style={styles.ratingStarM}
                    />
                  </View>
                  <View
                    style={[
                      styles.spotInfo,
                      mobile ? styles.spotsMobile : null,
                    ]}
                  >
                    <Text
                      style={styles.spotsLeft}
                    >{`${property.spots.length} Spots Left `}</Text>
                    <Text style={styles.spotsAt}>at </Text>
                    <Text style={styles.spotsPrice}>{`${prettyPrintCents(
                      property.spots.sort((a, b) => a.cost - b.cost)[0].cost
                    )} / Hour`}</Text>
                  </View>
                </View>
              </View>
              <Text style={styles.featuresLabel}>Property Features:</Text>
              <View style={styles.greyBox}>
                <View style={styles.greyBoxHr} />
              </View>
              <View style={styles.featuresContainer}>
                <Text style={styles.feature}>
                  <View style={[blurb(), styles.blurbYellow]} />
                  {property.charging ? "Charging" : "No Charging"}
                </Text>
                <Text style={styles.feature}>
                  <View style={[blurb(), styles.blurbOrange]} />
                  {property.covered ? "Covered" : "Unconvered"}
                </Text>
                <Text style={styles.feature}>
                  <View style={[blurb(), styles.blurbBlue]} />
                  {property.transit ? "Public Transit" : "Difficult Transit"}
                </Text>
                <Text style={styles.feature}>
                  <View style={[blurb(), styles.blurbGrey]} />
                  {property.cameras ? "Cameras" : "No Cameras"}
                </Text>
                <Text style={styles.feature}>
                  <View style={[blurb(), styles.blurbYellow]} />
                  {property.lit ? "Well Lit" : "Dimly Lit"}
                </Text>
                <Text style={styles.feature}>
                  <View style={[blurb(), styles.blurbGrey]} />
                  {property.gated ? "Gated" : "Not Gated"}
                </Text>
              </View>
            </View>
          </Animated.View>
        )}
      </AppContext.Consumer>
    );
  }
}
PropertyView.propTypes = {
  property: ProprtyShape,
};
PropertyView.defaultProps = {
  property: null,
};
export default PropertyView;
