import React, { useState, useEffect, useContext } from "react";
import { AppLoading } from "expo";
import * as Permissions from "expo-permissions";
import * as Network from "expo-network";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { StyleSheet, View, Platform, Image, AsyncStorage } from "react-native";

import { Router, Route, Switch, useHistory } from "../components/router";
import Nav from "../components/nav";
import PropertyView from "../components/propertyView";
import PaymentView from "../components/paymentView";
import ReservationView from "../components/reservationView";
import Alert from "../components/ui/alert";

import Profile from "./Profile";
import Map from "./Map";

import Api from "../lib/apihandler";
import brand from "../assets/styles";
import { StatusBar } from "expo-status-bar";

// eslint-disable-next-line no-undef

import AppContext from "../lib/context";

import Host from "./Host";

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: brand.white,
  },
  popupIcon: {
    width: 40,
    height: 40,
  },
  hr: {
    flexBasis: "100%",
    height: 2,
    backgroundColor: brand.darkGrey,
    marginVertical: 5,
  },
});
export default () => {
  const [state, setState] = useState({
    ready: false,
    location: null,
    payment: false,
    viewSidebar: false,
    alert: {
      visible: false,
      message: "",
      type: "info",
    },
    property: null,
    reservation: null,
  });
  useEffect(() => {
    if (global.api) {
      global.api.updateState(state, setState);
    }
  });
  const load = async () => {
    const { isInternetReachable } = await Network.getNetworkStateAsync();
    const { status } = await Permissions.askAsync(Permissions.LOCATION);
    // eslint-disable-next-line no-undef
    console.log("Dev: ", __DEV__);
    // eslint-disable-next-line no-undef

    global.api = new Api(
      __DEV__
        ? "http://192.168.1.14:8000/api"
        : "https://demo.parkshark.app/api",
      "",
      state,
      setState
    );
    const user = await AsyncStorage.getItem("user");
    if (!user || user == "null") return;

    const oldUser = JSON.parse(user);
    setState({ ...state, user: oldUser });
    const newUser = await global.api.fetchUser();
    console.log("Login as: ", { ...oldUser, ...newUser });
    setState({ ...state, user: { ...oldUser, ...newUser } });
  };

  const closeAlert = () => {
    const { alert } = state;
    setState({ ...state, alert: { ...alert, visible: false } });
  };

  const openProperty = (property) => {
    setState({ ...state, property, viewSidebar: true });
  };

  const showPayment = () => {
    setState({ ...state, payment: true });
  };
  const { ready, alert, property, payment, reservation, viewSidebar } = state;
  if (!ready) {
    return (
      <AppLoading
        startAsync={load}
        onFinish={() => setState({ ...state, ready: true })}
        onError={console.warn}
      />
    );
  }
  let searchStyle = {};
  let style = {};
  let MySafeArea = SafeAreaView;
  if (Platform.OS === "web") {
    style =
      payment || reservation
        ? { filter: "brightness(.3)", opacity: 0.2 }
        : { opacity: 1, pointerEvents: "auto" };
    searchStyle =
      payment || reservation
        ? { filter: "blur(3px) brightness(.8)" }
        : { filter: "none" };
    MySafeArea = View
  }
  return (
    <AppContext.Provider value={{ state, setState }}>
      <SafeAreaProvider>
        <Router>
          <MySafeArea style={{ flex: 1 }}>
            <View style={styles.main}>
              <Switch>
                <Route exact path="/">
                  <Map
                    openProperty={(e) => openProperty(e)}
                    property={property}
                    searchStyle={searchStyle}
                    reservation={reservation}
                  />
                </Route>

                <Route path="/profile" name="profile">
                  <Profile />
                </Route>
                <Route path="/host" name="host">
                  <Host.Dashboard />
                </Route>
                <Route path="/property/edit/:id" name="propertyedit">
                  <Host.EditProperty />
                </Route>
              </Switch>
              <Nav />
              {viewSidebar ? <PropertyView property={property} /> : null}

              {payment ? <PaymentView property={property} /> : null}
              {reservation ? (
                <ReservationView reservation={reservation} />
              ) : null}
              <Alert alert={alert} close={closeAlert} />
            </View>
          </MySafeArea>
        </Router>
        <StatusBar style="light" backgroundColor={brand.darkGrey} />
      </SafeAreaProvider>
    </AppContext.Provider>
  );
};
