function formatTime(date) {
  let hours = date.getHours();
  const AMPM = hours >= 12 ? 'PM' : 'AM';
  hours = hours > 12 ? hours - 12 : hours;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}${AMPM}`;
}

function formatDate(date) {
  const month = date.getMonth() + 1;
  const year = date.getYear() + 1900;
  const day = date.getDate();
  return `${month}/${day}/${year}`;
}

function formatRemaining(end) {
  const now = new Date();
  const diff = end.getTime() - now.getTime();
  const hours = Math.floor(diff / 3600000);
  const minutes = Math.floor((diff - hours * 3600000) / 60000);
  const seconds = Math.floor((diff - hours * 3600000 - minutes * 60000) / 1000);
  return `${hours} : ${minutes} : ${seconds}`;
}

export { formatTime, formatDate, formatRemaining };
